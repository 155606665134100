import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "../Coupon/coupon.css";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { presignedApi } from "../Course/CourseFun";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";
import { addBlog, editBlog, getBlogCategory } from "./BlogApi";
import { useLocation, useNavigate } from "react-router-dom";
import { dispatchtoast, getBase64, messageConfiguration } from "../../../Utils";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddBlogCategory from "./AddBlogCategory";
function AddBlog() {
  const location = useLocation();
  const fill = location.state?.fill;
  const from = location.state?.from;
  const { Option } = Select;
  const [uploadImageProgress, setUploadImageProgress] = useState(0);
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0);
  const [blogCategorydata, setblogCategoryData] = useState([]);
  const [coverImage, setcoverImage] = useState(fill?.coverImage ?? "");
  const [editorText, setEditorText] = useState(
    fill?.components?.find((i) => i.type === "rte")?.value || ""
  );

  const [componentImage, setcomponentImage] = useState(
    fill.components?.map((i) => (i.type === "image" ? i.value : ""))
  );

  const [componentVideo, setComponentVideo] = useState(
    fill.components?.filter((i) => i.type === "youtube")?.map((i) => i.value) ||
      ""
  );
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const navigate = useNavigate();

  const initialValues = {
    title: fill.title,
    subtitle: fill.subtitle,
    slug: fill.slug,
    metaTags: fill.metaTags,
    categorySlug: fill.categorySlug,
    categoryName: fill.categoryName,
    category: fill.category,
    coverImage: fill.coverImage,
    highlighted: fill.highlighted,
    blogCategory: fill.blogCategory,
    components: fill.components?.map((i, id) => {
      switch (i.type) {
        case "image":
          return {
            type: i.type,
            value: i.value || "",
            tabs: [],
            content: [],
            title: [],
          };
        case "youtube":
          return {
            type: i.type,
            value: i.value || "",
            tabs: [],
            content: [],
            title: [],
          };
        case "tabs":
          return {
            type: i.type,
            tabs: Array.isArray(i.tabs)
              ? i.tabs
              : i.tabs?.split(",")?.map((tab) => tab.trim()),
            content: Array.isArray(i.content)
              ? i.content
              : i.content?.split(",")?.map((content) => content.trim()),
          };
        case "collapsibles":
          return {
            type: "collapsibles",
            title: i.collapsibleSections?.map((section) => section.title) || [],
            content:
              i.collapsibleSections?.map((section) => section.content) || [],
          };
        default:
          return {
            type: i.type,
            value: i.value,
          };
      }
    }),
  };
  const handleUpload = async (event, type) => {
    console.log(event, type);
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }
      const fileExtension = selectedFile.type.split("/").pop();
      const { preSignedUploadUrl, Key } = await presignedApi(
        `?mimetype=${fileExtension}`
      );
      console.log({ preSignedUploadUrl, Key, type });

      if (preSignedUploadUrl) {
        await axios.put(preSignedUploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setUploadImageProgress(percentCompleted);
          },
        });
        setcoverImage(Key);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleEditorChange = (description) => {
    // setFormData({ ...formData, description });
    console.log(description);
  };
  const handleComponentUpload = async (event, type, index) => {
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }
      const fileExtension = selectedFile.type.split("/").pop();
      const { preSignedUploadUrl, Key } = await presignedApi(
        `?mimetype=${fileExtension}`
      );
      console.log({ preSignedUploadUrl, Key, type, index });

      if (preSignedUploadUrl) {
        await axios.put(preSignedUploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (type === "image") {
              setUploadImageProgress(percentCompleted);
            } else {
              setUploadVideoProgress(percentCompleted);
            }
          },
        });

        if (type === "image") {
          setcomponentImage((prev) => ({ ...prev, [index]: Key }));
        } else {
          setComponentVideo((prev) => ({ ...prev, [index]: Key }));
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const handleImageUpload = function () {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(
        "https://api.imgbb.com/1/upload?key=YOUR_API_KEY",
        {
          method: "POST",
          body: formData,
        }
      );
      const result = await response.json();
      const imageUrl = result.data.url;

      const quill = this.quill;
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", imageUrl);
    };
  };

  const handleVideoEmbed = function () {
    const url = prompt("Enter the video URL:");
    if (url) {
      const quill = this.quill;
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "video", url);
    }
  };
  const modules = {
    toolbar: {
      container: [
        [{ font: [] }, { size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ align: [] }],
        ["link", "image", "video"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["blockquote", "code-block"],
        ["clean"],
      ],
      handlers: {
        image: handleImageUpload,
        video: handleVideoEmbed,
      },
    },
  };
  const handleChangeCK = (event, editor) => {
    if (editor && typeof editor.getData === "function") {
      const data = editor.getData();
      setEditorText(data);
    }
  };
  const handlePasteEvent = (event, editor) => {
    console.log({ event });
    const clipboardData = event.clipboardData || window.clipboardData;
    if (clipboardData.types.includes("Files")) {
      const file = clipboardData.files[0];
      console.log(file);
    }
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "link",
    "image",
    "list",
    "bullet",
    "heading",
  ];

  const initialEditorValue =
    from === "edit"
      ? fill?.components?.find((i) => i.type === "rte")?.value || ""
      : "";

  const createBlog = (changedValues) => {
    let payload = {
      title: changedValues.title,
      subtitle: changedValues.subtitle,
      slug: changedValues.slug,
      categoryName: changedValues.categoryName,
      categorySlug: changedValues.categorySlug,
      coverImage: coverImage,
      metaTags: changedValues.metaTags,
      highlighted: changedValues.highlighted,
      blogCategory: changedValues.blogCategory,
      components: changedValues.components?.map((i, index) => {
        switch (i.type) {
          case "rte":
            return {
              type: i.type,
              value: editorText,
            };

          case "tabs": {
            const tabsSection = changedValues?.components?.filter(
              (section) => section.type === "tabs"
            );
            if (tabsSection.length > 0) {
              const tabData = tabsSection[0];
              return {
                type: tabData.type,
                tabs: tabData.tabsSections?.map((section) => section.tabs),
                content: tabData.tabsSections?.map(
                  (section) => section.content
                ),
              };
            }

            return {
              type: "tabs",
              tabs: [],
              content: [],
            };
          }
          case "collapsibles": {
            const collapsibles = changedValues?.components?.filter(
              (section) => section.type === "collapsibles"
            );

            if (collapsibles.length > 0) {
              const collapsible = collapsibles[0];

              return {
                type: collapsible.type,
                title: collapsible.collapsibleSections?.map(
                  (section) => section.title
                ),
                content: collapsible.collapsibleSections?.map(
                  (section) => section.content
                ),
              };
            }

            return {
              type: "collapsibles",
              title: [],
              content: [],
            };
          }
          default:
            return {
              // type: i.type,
              // value: i.value,
            };
        }
      }),
    };

    {
      from === "edit"
        ? editBlog(payload, `${fill.slug}`)
            .then((response) => {
              if (response.success) {
                navigate("/blog/blogData");
                form.resetFields();
              } else {
                dispatchtoast("Fields can not be empty", true);
              }
            })
            .catch((error) => {
              console.log(error);
            })
        : addBlog(payload)
            .then((response) => {
              if (response.success) {
                navigate("/blog/blogData");
                form.resetFields();
                setcoverImage(null);
                setcomponentImage(null);
                setUploadImageProgress(0);
                document.getElementById("upload-image").value = null;
              } else {
                dispatchtoast("Something went wrong", true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
    }
  };
  const FetchBlogCategoryData = () => {
    getBlogCategory()
      .then((response) => {
        if (response.status) {
          setblogCategoryData(response.data);
        }
      })
      .catch((err) => console.error("Error fetching data", err));
  };

  useEffect(() => {
    FetchBlogCategoryData();
  }, []);

  return (
    <div>
      {/* {from === "add" ? (
        <>
          <Divider
            orientation="left"
            className="text-lg font-dmsans font-semibold"
          >
            Add Blog Category
          </Divider>
          <AddBlogCategory />
        </>
      ) : (
        ""
      )} */}

      <Divider orientation="left" className="text-lg font-dmsans font-semibold">
        {from === "edit" ? "Update" : "Add"} Blog Data
      </Divider>
      <Form
        className="bg-white p-6"
        layout="vertical"
        onFinishFailed={(err) => console.log(err)}
        onFinish={createBlog}
        form={form}
        ref={formRef}
        initialValues={
          from === "edit"
            ? initialValues
            : {
                metaTags: [{ name: "", content: "" }],
                components: [{ type: "", value: "" }],
              }
        }
      >
        <Row className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <Col>
            <Form.Item
              label={<p className="font-medium">Title</p>}
              name="title"
              rules={[
                { required: true, message: "Please enter Title" },
                {
                  min: 6,
                  message: "Title must be at least 4 characters",
                },
              ]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Slug</p>}
              name="slug"
              rules={[
                { required: true, message: "Please enter Slug" },
                {
                  min: 6,
                  message: "Slug must be at least 6 characters",
                },
                {
                  pattern: /^[a-zA-Z0-9-]+$/,
                  message:
                    "Slug can only contain letters, numbers, and hyphens",
                },
              ]}
            >
              <Input placeholder="Enter Slug" />
            </Form.Item>
          </Col>{" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">SubTitle</p>}
              name="subtitle"
            >
              <Input placeholder="Enter SubTitle" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={<p className="font-medium">Category Slug</p>}
              name="categorySlug"
              rules={[
                { required: true, message: "Please enter Category Slug" },
                {
                  min: 6,
                  message: "Slug must be at least 6 characters",
                },
                {
                  pattern: /^[a-zA-Z0-9-]+$/,
                  message:
                    "Category Slug can only contain letters, numbers, and hyphens",
                },
              ]}
            >
              <Input placeholder="Enter Category Slug" />
            </Form.Item>
          </Col>{" "}
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {" "}
          <Col>
            <Form.Item
              label={<p className="font-medium">Category Name</p>}
              name="categoryName"
              rules={[
                { required: true, message: "Please enter a Category Name" },
                {
                  type: "string",
                  message: "Please enter a valid Category Name",
                },
              ]}
            >
              <Input placeholder="Enter Category" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={<p className="font-medium">Blog Category</p>}
              name="blogCategory"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select placeholder="Select an option">
                {blogCategorydata.map((option, index) => (
                  <Select.Option key={index} value={option.categoryName}>
                    {option.categoryName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className="grid grid-cols-1 gap-4">
          <p className="font-medium">Meta Tags</p>
          <Col>
            <Form.Item
              validateTrigger="onSubmit"
              style={{ marginBottom: "16px" }}
            >
              <Form.List
                name="metaTags"
                rules={[
                  {
                    validator: async (_, metaTags) => {
                      if (!metaTags || metaTags.length < 1) {
                        return Promise.reject(
                          new Error("Please add at least one tag")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <div
                          key={key}
                          style={{ marginBottom: "16px" }}
                          className="grid grid-cols-1 sm:grid-cols-[1%_47%_40%_8%] gap-4"
                        >
                          <p className="font-medium">{index + 1}•</p>

                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            fieldKey={[fieldKey, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter meta tag name",
                              },
                            ]}
                          >
                            <Select placeholder="Select a tag">
                              {[
                                "description",
                                "title",
                                "keywords",
                                "robots",
                                "author",
                              ].map((option) => (
                                <Option key={option} value={option}>
                                  {option.charAt(0).toUpperCase() +
                                    option.slice(1)}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, "content"]}
                            fieldKey={[fieldKey, "content"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter meta tag content",
                              },
                            ]}
                          >
                            <Input.TextArea placeholder="Enter meta tag content" />
                          </Form.Item>

                          <Button
                            onClick={() => remove(name)}
                            disabled={fields.length === 1}
                            className="bg-red-500 text-xs text-white border-none rounded-none"
                          >
                            Remove
                          </Button>
                        </div>
                      )
                    )}

                    <Form.Item>
                      <Button
                        className="bg-orange text-xs text-white w-1/2 rounded-none"
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Meta Tag
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Col>
        </Row>

        <Row className="grid grid-cols-1 gap-4">
          <p className="font-medium">Components</p>
          <Col>
            <Form.List
              name="components"
              rules={[
                {
                  validator: async (_, components) => {
                    if (!components || components.length < 1) {
                      return Promise.reject(
                        new Error("Please add at least one component")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => {
                      const componentType = form.getFieldValue([
                        "components",
                        index,
                        "type",
                      ]);
                      const isRTEtype = componentType === "rte";
                      const isTabsType = componentType === "tabs";
                      const isCollapsiblesType =
                        componentType === "collapsibles";

                      return (
                        <div
                          key={key}
                          style={{ marginBottom: "16px" }}
                          className="grid grid-cols-1 sm:grid-cols-[1%_15%_70%_10%] gap-4"
                        >
                          <p className="font-medium">{index + 1}•</p>
                          <Form.Item
                            {...restField}
                            name={[name, "type"]}
                            fieldKey={[fieldKey, "type"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter component type",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select an option"
                              onChange={(value) => {
                                form.setFieldsValue({
                                  components: form
                                    .getFieldValue("components")
                                    .map((component, idx) =>
                                      idx === index
                                        ? { ...component, value: "" }
                                        : component
                                    ),
                                });
                              }}
                            >
                              {["rte", "tabs", "collapsibles"].map(
                                (option, idx) => (
                                  <Select.Option key={idx} value={option}>
                                    {option.charAt(0).toUpperCase() +
                                      option.slice(1)}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </Form.Item>

                          {isTabsType ? (
                            <Form.List
                              name={[name, "tabsSections"]}
                              initialValue={
                                from === "edit" &&
                                fill?.components?.some((i) => i.type === "tabs")
                                  ? fill.components
                                      .filter((i) => i.type === "tabs")[0]
                                      .tabs.map((tab, index) => ({
                                        tabs: tab,
                                        content: fill.components.filter(
                                          (i) => i.type === "tabs"
                                        )[0].content[index],
                                      }))
                                  : []
                              }
                            >
                              {(
                                tabFields,
                                { add: addTab, remove: removeTab }
                              ) => (
                                <Row className="grid grid-cols-1">
                                  {tabFields.map((tabField) => (
                                    <Space
                                      key={tabField.key}
                                      align="baseline"
                                      style={{ marginBottom: 8 }}
                                    >
                                      <Form.Item
                                        name={[tabField.name, "tabs"]}
                                        fieldKey={[tabField.fieldKey, "tabs"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Enter tab title",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Tab Title" />
                                      </Form.Item>
                                      <Form.Item
                                        name={[tabField.name, "content"]}
                                        fieldKey={[
                                          tabField.fieldKey,
                                          "content",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Enter tab content",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Tab Content" />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => removeTab(tabField.name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => addTab()}
                                      icon={<PlusOutlined />}
                                    >
                                      Add Tab
                                    </Button>
                                  </Form.Item>
                                </Row>
                              )}
                            </Form.List>
                          ) : isCollapsiblesType ? (
                            <Form.List
                              name={[name, "collapsibleSections"]}
                              initialValue={
                                from === "edit" &&
                                fill?.components?.some(
                                  (i) => i.type === "collapsibles"
                                )
                                  ? fill.components
                                      .filter(
                                        (i) => i.type === "collapsibles"
                                      )[0] // Reference collapsibles component
                                      .title.map((title, index) => ({
                                        title: title,
                                        content: fill.components.filter(
                                          (i) => i.type === "collapsibles"
                                        )[0].content[index], // Correct content reference
                                      }))
                                  : []
                              }
                            >
                              {(
                                collapseFields,
                                { add: addCollapse, remove: removeCollapse }
                              ) => (
                                <Row className="grid grid-cols-1">
                                  {collapseFields.map((collapseField) => (
                                    <Space
                                      key={collapseField.key}
                                      align="baseline"
                                      style={{ marginBottom: 8 }}
                                    >
                                      <Form.Item
                                        name={[collapseField.name, "title"]}
                                        fieldKey={[
                                          collapseField.fieldKey,
                                          "title",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Enter collapsible title",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Collapsible Title" />
                                      </Form.Item>
                                      <Form.Item
                                        name={[collapseField.name, "content"]}
                                        fieldKey={[
                                          collapseField.fieldKey,
                                          "content",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Enter collapsible content",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Collapsible Content" />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() =>
                                          removeCollapse(collapseField.name)
                                        }
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => addCollapse()}
                                      icon={<PlusOutlined />}
                                    >
                                      Add Collapsible Section
                                    </Button>
                                  </Form.Item>
                                </Row>
                              )}
                            </Form.List>
                          ) : (
                            <Form.Item
                              {...restField}
                              name={[name, "value"]}
                              fieldKey={[fieldKey, "value"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter component value",
                                },
                              ]}
                            >
                              <CKEditor
                                editor={ClassicEditor}
                                data={initialEditorValue}
                                config={{
                                  toolbar: {
                                    items: [
                                      "heading",
                                      "|",
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "subscript",
                                      "superscript",
                                      "|",
                                      "link",
                                      "imageUpload",
                                      "mediaEmbed",
                                      "|",
                                      "bulletedList",
                                      "numberedList",
                                      "todoList",
                                      "|",
                                      "alignment",
                                      "outdent",
                                      "indent",
                                      "blockQuote",
                                      "|",
                                      "insertTable",
                                      "tableColumn",
                                      "tableRow",
                                      "mergeTableCells",
                                      "|",
                                      "undo",
                                      "redo",
                                      "|",
                                      "fontBackgroundColor",
                                      "fontColor",
                                      "fontSize",
                                      "fontFamily",
                                      "|",
                                      "horizontalLine",
                                      "pageBreak",
                                      "specialCharacters",
                                      "removeFormat",
                                    ],
                                  },
                                  mediaEmbed: {
                                    previewsInData: true,
                                    providers: [
                                      {
                                        name: "youtube",
                                        url: /^https?:\/\/(www\.)?(youtube\.com|youtu\.be)\/.+/,
                                        html: (match) => {
                                          const url = match[0];
                                          const id =
                                            /(?:youtube\.com\/.*v=|youtu\.be\/)([^&/]+)/.exec(
                                              url
                                            )[1];
                                          return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" frameborder="0" allowfullscreen></iframe>`;
                                        },
                                      },
                                      {
                                        name: "vimeo",
                                        url: /^https?:\/\/(www\.)?vimeo\.com\/.+/,
                                        html: (match) => {
                                          const url = match[0];
                                          const id =
                                            /vimeo\.com\/([0-9]+)/.exec(url)[1];
                                          return `<iframe width="560" height="315" src="https://player.vimeo.com/video/${id}" frameborder="0" allowfullscreen></iframe>`;
                                        },
                                      },
                                    ],
                                  },
                                  table: {
                                    contentToolbar: [
                                      "tableColumn",
                                      "tableRow",
                                      "mergeTableCells",
                                      "tableCellProperties",
                                      "tableProperties",
                                    ],
                                  },
                                  extraPlugins: [uploadPlugin],
                                }}
                                onChange={(event, editor) =>
                                  handleChangeCK(event, editor)
                                }
                                onPaste={(event, editor) =>
                                  handlePasteEvent(event, editor)
                                }
                              />
                            </Form.Item>
                          )}

                          <Button
                            onClick={() => remove(name)}
                            disabled={fields.length === 1}
                            className="bg-red-500 text-xs text-white border-none rounded-none"
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    }
                  )}
                  <Form.Item>
                    <Button
                      className="bg-orange text-xs text-white w-1/2 rounded-none"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Components
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Col>
            <Form.Item
              name="coverImage"
              label={<p className="font-medium">Cover image</p>}
            >
              {coverImage ? (
                <div className="relative h-[100px] aspect-video">
                  <Image
                    src={`${UPLOAD_ENDPOINT}${coverImage}`}
                    className="h-[100px] object-cover aspect-video"
                    height={100}
                    width={180}
                  />

                  <button
                    type="button"
                    onClick={() =>
                      document.getElementById("cover-image").click()
                    }
                    className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 text-white flex items-center justify-center text-sm font-medium hover:bg-opacity-70"
                  >
                    Change Image
                  </button>
                  <Input
                    type="file"
                    id="cover-image"
                    style={{
                      opacity: "0",
                      position: "absolute",
                      zIndex: "-1",
                    }}
                    onChange={(e) => handleUpload(e)}
                    accept="image/*"
                  />
                </div>
              ) : (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2"
                  direction="vertical"
                  size="large"
                >
                  <label
                    htmlFor="cover-image"
                    className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                  >
                    <div className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                      {uploadImageProgress > 0 && uploadImageProgress < 100 ? (
                        <div className="animate-pulse h-[100px] aspect-video">
                          <div
                            className={`bg-slate-300 h-[100px] flex flex-row justify-center items-center`}
                            style={{ width: `${uploadImageProgress}%` }}
                          >
                            <p>{`${uploadImageProgress}%`}</p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <p className="flex justify-center items-center m-2"></p>
                          <p>Select Cover image</p>
                        </div>
                      )}
                    </div>
                  </label>
                  <div>
                    <Input
                      type="file"
                      id="cover-image"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                      onChange={(e) => handleUpload(e)}
                      accept="image/*"
                    />
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label={<p className="font-medium">Highlighted Status</p>}
              name="highlighted"
              rules={[{ required: true, message: "Please select an option" }]}
            >
              <Select placeholder="Select an option">
                {[
                  { label: "True", value: true },
                  { label: "False", value: false },
                ].map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className="flex justify-between">
          <Form.Item>
            <Button
              onClick={() => form.resetFields()}
              className="rounded-none p-[0px_32px_0px_32px]   text-sm font-dmsans font-semibold text-gray2 "
              size="small"
              htmlType="onSubmit"
            >
              Cancel
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold  bg-orange text-xs "
              size="small"
              htmlType="submit"
            >
              {from === "edit" ? "Edit" : "Create"} Blog
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
const uploadAdapter = (loader) => {
  return {
    upload: async () => {
      const file = await loader.file;
      const base64 = await getBase64(file);
      return {
        default: base64,
      };
    },
  };
};
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

export default AddBlog;
