import { Button, Divider, Image, Pagination, Popconfirm, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { ExtractDate, ExtractTime } from "../../../Utils";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "../Coupon/coupon.css";
import { useLocation, useNavigate } from "react-router-dom";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";
import { getContacts } from "./ContactApiFunction";

function ContactDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [contactData, setContactData] = useState([]);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  function FetchContact() {
    getContacts(`page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.status) {
          setContactData(response.data);
          setTotalPages(response.pagination?.totalPages);
          setCurrentPage(response.pagination?.currentPage);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  useEffect(() => {
    FetchContact(currentPage);
  }, [currentPage]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">
          {record.firstName} {record.lastName}
        </p>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.email}</p>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.subject}</p>
      ),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <Tooltip title={record.message}>
          <p className="text-xs font-dmsans">
            {record.message.length > 35
              ? `${record.message.substring(0, 35)}...`
              : record.message}
          </p>
        </Tooltip>
      ),
    },
    
    {
      title: "Source ",
      dataIndex: "source",
      key: "source",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.source}</p>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">
          {ExtractDate(record.createdAt)}.{ExtractTime(record.createdAt)}
        </p>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
        <div className="ml-10">
          <p className="text-primary font-dmsans leading-7">Hello!</p>
          <p className="text-lg font-bold font-dmsans ">Contact Details</p>
        </div>
      </div>

      <div className="ml-10 md:ml-20 mt-8">
        {/* <Divider
          orientation="left"
          className="text-lg font-dmsans font-semibold"
        >
          Event Participants Details
        </Divider> */}
        <div
          style={{ maxHeight: "550px", overflow: "auto" }}
          className="bg-white"
        >
          <Table
            columns={columns}
            loading={isLoading}
            dataSource={contactData}
            className="w-screen sm:w-screen"
            // scroll={{
            //   y: 240,
            // }}
            pagination={false}
          />
        </div>
        <Pagination
          className="mt-2  w-full flex justify-center items-center "
          onChange={handlePageChange}
          current={currentPage}
          defaultCurrent={1}
          total={10 * totalPage}
          showSizeChanger={false}
        />
      </div>
    </>
  );
}
export default ContactDetails;
