import React, { Suspense, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../ImageController/Index";
import { Button, Checkbox, Divider, Form, Image, message, Spin } from "antd";
import {
  dispatchtoast,
  emailPattern,
  makeLabel,
  messageConfiguration,
  setLocalStorageItem,
} from "../../Utils";
import CommonInput from "../../CommonComponents/CommonInput";
import { useNavigate } from "react-router-dom";
import { ForgotPassoword } from "./AuthFun";
import { FaSmile } from "react-icons/fa";

const ForgetPassword = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleForgetPassword = (val) => {
    setBtnLoading(true);
    let payload = {
      email: val.email,
    };
    ForgotPassoword(payload)
      .then((res) => {
        if (res.status) {
          setSuccessMessage(res.response);
          // setLocalStorageItem("accessToken", res.token);
          // setLocalStorageItem("IsLoggesIn", true);
          // navigate("/resetPassword");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <Spin spinning={true}></Spin>
        </div>
      }
    >
      {successMessage ? (
        <>
          <div className=" flex justify-center items-center h-screen">
            <p className="font-extrabold text-2xl text-green-600 font-poppins underline underline-offset-4">
              {successMessage}!
            </p>
          </div>
        </>
      ) : (
        <div className="container   items-center h-screen">
          <div className="grid grid-cols-2 place-items-center ">
            <div className="w-full bg-authBg h-screen hidden md:flex justify-center items-center ">
              <LazyLoadImage
                src={Images.ForgetPass}
                alt="signupimage"
                className="w-[70%]"
              />
            </div>
            <div className="w-full h-screen flex justify-center items-center col-span-2 md:col-span-1">
              <div className="w-3/4 m-auto">
                <div className="font-poppins text-start text-2xl font-normal mb-8">
                  <p>Forget Password?</p>
                </div>
                <Form onFinish={(val) => handleForgetPassword(val)}>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: "Email Is Required",
                      },
                      {
                        pattern: emailPattern,
                        message: "Please Enter Valid Email",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label={makeLabel("Email Address")}
                  >
                    <CommonInput
                      placeHolder="Email address"
                      className=" hover:border p-3 hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    />
                  </Form.Item>

                  <div className="flex items-center mt-4 ">
                    <Button
                      loading={btnLoading}
                      type="primary"
                      htmlType="submit"
                      style={{ color: "white" }}
                      prefix="jj"
                      className="font-poppins p-5 text-white  rounded-none bg-primary w-full"
                    >
                      Request For Reset Password
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Suspense>
  );
};

export default ForgetPassword;
