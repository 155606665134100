import { Button, Col, Divider, Form, message, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";

const Publish = ({
  setFormData,
  formData,
  onSubmit,
  setActiveTab,
  activeTab,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData]);
  const handleSubmit = (changedValues) => {
    form
      .validateFields()
      .then((values) => {
        const data = { ...formData, ...changedValues };

        onSubmit(data);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <div className="ml-2 grid grid-cols-1 sm:grid-cols-2 ">
        <div>
          <p className="text-lg font-dmsans font-semibold">Publish Course</p>
        </div>
        {/* <div className="flex justify-end gap-2 mr-8">
          <p className="bg-[#FFEEE8] text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none">
            Save
          </p>
          <p className="bg-white text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-md">
            Save & Preview
          </p>
        </div> */}
      </div>
      <Divider />
      <div>
        <Form
          form={form}
          onFinish={(changedValues) => handleSubmit(changedValues)}
          className="bg-white p-2 font-dmsans font-medium text-xs text-gray2"
          layout="vertical"
        >
          <p className="text-[#1D2026] text-lg font-medium mb-4">Message</p>
          <Row className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Col>
              <Form.Item
                name="welcomeMessage"
                label={
                  <span className="text-[#1D2026] text-sm font-normal">
                    Welcome Message
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please Enter Welcome Message",
                  },
                ]}
              >
                <TextArea
                  placeholder="Enter course starting message here..."
                  type="text"
                  style={{
                    height: 120,
                    resize: "none",
                  }}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="congratulationMessage"
                label={
                  <span className="text-[#1D2026] text-sm font-normal">
                    Congratulations Message
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please Enter Congratulations Message",
                  },
                ]}
              >
                <TextArea
                  placeholder="Enter your course completed message here..."
                  type="text"
                  style={{
                    height: 120,
                    resize: "none",
                  }}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="flex justify-between p-6">
            <Form.Item>
              <Button
                className="rounded-none p-[0px_32px_0px_32px] text-sm font-dmsans font-semibold text-gray2"
                size="large"
                onClick={() => setActiveTab("3")}
              >
                Prev Step
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold bg-orange text-xs"
                size="large"
                htmlType="onSubmit"
                // onClick={handleSubmit}
              >
                Submit For Preview
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Publish;
