import {
  Button,
  Divider,
  Image,
  message,
  Pagination,
  Popconfirm,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { ExtractDate, ExtractTime, messageConfiguration } from "../../../Utils";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "../Coupon/coupon.css";
import { useNavigate } from "react-router-dom";
import {
  deleteBlog,
  editBlog,
  getBlog,
  modifyHighlightedStatus,
} from "./BlogApi";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";

function Blog() {
  const [currentPage, setCurrentPage] = useState("");
  const [totalPage, setTotalPages] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const [blogData, setblogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState({});
  const navigate = useNavigate();
  function FetchBlogData() {
    getBlog(`limit=3&page=${currentPage}`)
      .then((response) => {
        if (response.status) {
          setblogData(response.data);
          setTotalPages(response?.pagination?.totalPages);
          setCurrentPage(response?.pagination?.currentPage);
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setIsLoading(false);
        setLoadingMore(false);
      });
  }

  useEffect(() => {
    FetchBlogData();
  }, [currentPage]);

  function DeleteAction(checked, record) {
    deleteBlog(`${record._id}`)
      .then((response) => {
        if (response.status) {
          message.open(messageConfiguration("success", response.message, 3));
          FetchBlogData(currentPage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns = [
    {
      title: "Cover Image",
      dataIndex: "coverImage",
      key: "coverImage",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => {
        return (
          <>
            <Image
              src={`${UPLOAD_ENDPOINT}${record.coverImage}`}
              height={100}
              width={100}
            />
          </>
        );
      },
    },
    {
      title: "Blog Title",
      dataIndex: "title",
      key: "title",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.title}</p>
      ),
    },
    {
      title: "Blog SubTitle",
      dataIndex: "subtitle",
      key: "subtitle",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.subtitle}</p>
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.slug}</p>
      ),
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.categoryName}</p>
      ),
    },
    {
      title: "Highlighted Status",
      key: "highlighted",
      dataIndex: "highlighted",
      render: (data, record) => {
        console.log(record);
        return (
          <Switch
            // defaultValue={record.highlight}
            checked={record.highlighted}
            checkedChildren="true"
            unCheckedChildren="false"
            onChange={(checked) => ModifyHighlightedStatus(checked, record)}
          />
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">
          {ExtractDate(record.createdAt)}•{ExtractTime(record.createdAt)}
        </p>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <Button size="small" className="text-black text-sm">
          <EditOutlined
            onClick={() =>
              navigate("/blog/addBlog", {
                state: {
                  fill: record,
                  from: "edit",
                },
              })
            }
          />
        </Button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <Popconfirm
          title={
            <div>
              <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                Are you sure you want to delete this Blog History?
              </p>
            </div>
          }
          onConfirm={() => DeleteAction(undefined, record)}
          okText="Delete"
          cancelText="Cancel"
        >
          <DeleteOutlined className="text-xs font-dmsans" title="Delete" />
        </Popconfirm>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const ModifyHighlightedStatus = (checked, record) => {
    console.log(checked);

    let payload = {
      title: record.title,
      subtitle: record.subtitle,
      slug: record.slug,
      categoryName: record.categoryName,
      categorySlug: record.categorySlug,
      coverImage: record.coverImage,
      highlighted: checked,
      metaTags: record.metaTags,
      components: record.components?.map((i, index) => {
        switch (i.type) {
          case "rte":
            return {
              type: i.type,
              value: i.value,
            };

          case "tabs": {
            const tabsSection = record?.components?.filter(
              (section) => section.type === "tabs"
            );
            if (tabsSection.length > 0) {
              const tabData = tabsSection[0];
              return {
                type: tabData.type,
                tabs: tabData.tabsSections?.map((section) => section.tabs),
                content: tabData.tabsSections?.map(
                  (section) => section.content
                ),
              };
            }

            return {
              type: "tabs",
              tabs: [],
              content: [],
            };
          }
          case "collapsibles": {
            const collapsibles = record?.components?.filter(
              (section) => section.type === "collapsibles"
            );

            if (collapsibles.length > 0) {
              const collapsible = collapsibles[0];

              return {
                type: collapsible.type,
                title: collapsible.collapsibleSections?.map(
                  (section) => section.title
                ),
                content: collapsible.collapsibleSections?.map(
                  (section) => section.content
                ),
              };
            }

            return {
              type: "collapsibles",
              title: [],
              content: [],
            };
          }
          default:
            return {
              // type: i.type,
              // value: i.value,
            };
        }
      }),
    };
    editBlog(payload, `${record.slug}`)
      .then((response) => {
        if (response.success) {
          FetchBlogData(currentPage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
        <div className="ml-10">
          <p className="text-primary font-dmsans leading-7">Hello!</p>
          <p className="text-lg font-bold font-dmsans "> Blog Data</p>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold flex  bg-orange text-xs "
          size="small"
          onClick={() =>
            navigate("/blog/addBlog", {
              state: {
                fill: filldata,
                from: "add",
              },
            })
          }
        >
          Add Blog
        </Button>
      </div>
      <div className="ml-10 md:ml-20 mt-8">
        {/* <AddBlog
          Fill={filldata}
          setFill={setFilldata}
          from={from}
          setFrom={setFrom}
          //   apiCall={FetchCouponList}
        /> */}
        <Divider
          orientation="left"
          className="text-lg font-dmsans font-semibold"
        >
          Blog Details
        </Divider>
        <div
          style={{ maxHeight: "600px", overflow: "auto" }}
          className="bg-white"
        >
          <Table
            columns={columns}
            dataSource={blogData}
            className="w-screen sm:w-screen"
            // scroll={{
            //   y: 240,
            // }}
            pagination={false}
          />
        </div>
        <Pagination
          className="mt-2  w-full flex justify-center items-center "
          onChange={handlePageChange}
          current={currentPage}
          defaultCurrent={1}
          total={10 * totalPage}
          showSizeChanger={false}
        />
      </div>
    </>
  );
}
export default Blog;
