import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Input, message, Modal } from "antd";
import { GrEdit } from "react-icons/gr";
import IconButton from "../../../../../CommonComponents/IconButton";
import { MdDeleteOutline } from "react-icons/md";
import ReactPlayer from "react-player";
import { getYtDurationApi } from "../../CourseFun";

const YTModal = ({
  ytLink,
  updateAsset,
  deleteAsset,
  index,
  ytLinkIndex,
  formData,
  setFormData,
}) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const [alertVisible, setAlertVisible] = useState(false);
  const baseUrl = "https://www.youtube.com/watch?v=";
  const getYtDuration = async (videoId) => {
    console.log(videoId, "videoID");
    try {
      const res = await getYtDurationApi(`?videoId=${videoId}`);
      if (res.status) {
        return res.data;
      } else {
        return {
          duration: null,
          title: null,
        };
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const handleValuesChange = async (changedValues) => {
    try {
      const { title, link, previewLink } = { ...changedValues };

      const videoIdRegex = /[?&]v=([^&#]*)/;

      const linkId = link.match(videoIdRegex)?.[1];
      const previewLinkId = previewLink.match(videoIdRegex)?.[1];

      if (!linkId || !previewLinkId) {
        message.error("Invalid Link or Preview Link format", 2);
        return;
      }
      const { duration, title: ytTitle } = await getYtDuration(linkId);

      const data = {
        title,
        duration,
        link: linkId,
        previewLink: previewLinkId,
      };
      console.log(data, "data");

      if (!data.duration || !data.link || !data.title || !data.previewLink) {
        message.error("Invalid Link or Preview Link passed", 2);
      } else {
        updateAsset(index, "ytLinks", ytLinkIndex, data);
        setOpen(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onOpen = () => {
    form.setFieldsValue({
      title: ytLink.title || "",
      link: ytLink.link ? `${baseUrl}${ytLink.link}` : "",
      previewLink: ytLink.previewLink ? `${baseUrl}${ytLink.previewLink}` : "",
    });
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!ytLink.link || !ytLink.title) {
      onOpen();
    }
  }, [ytLink]);

  return (
    <>
      <Modal
        footer={null}
        open={open}
        onCancel={onCancel}
        title="Add YT Link Details"
      >
        <Form
          className="bg-white p-6"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={(changedValues) => {
            handleValuesChange(changedValues);
          }}
          form={form}
          ref={formRef}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please enter title",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link"
            rules={[
              {
                required: true,
                message: "Please enter link",
              },
            ]}
          >
            <Input type="link" />
          </Form.Item>
          <Form.Item
            name="previewLink"
            label="Preview Link"
            rules={[
              {
                required: true,
                message: "Please enter preview link",
              },
            ]}
          >
            <Input type="link" />
          </Form.Item>

          <div>
            <Form.Item className="flex justify-center mt-4">
              <Button
                className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold bg-orange text-xs"
                size="large"
                htmlType="onSubmit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <div className="h-[50px] aspect-video d-flex justify-center items-center relative">
        {true || (ytLink && ytLink.link && ytLink.link.length) ? (
          <>
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${ytLink.link}`}
              autoPlay={false}
              controls={false}
              height={"50px"}
              width={"88.89px"}
              className=" h-[50px] aspect-video"
            >
              Your browser does not support the video tag.
            </ReactPlayer>
            <div className="h-[50px] aspect-video flex flex-row justify-center items-center gap-2 absolute top-0 left-0">
              <IconButton Icon={GrEdit} onClick={onOpen} />
              <IconButton
                Icon={MdDeleteOutline}
                onClick={() => deleteAsset(index, "ytLinks", ytLinkIndex)}
              />
            </div>
          </>
        ) : (
          <div className="animate-pulse">
            <div className="bg-slate-300 h-[50px] aspect-video">
              <div className="h-[50px] flex flex-row justify-center items-center gap-2">
                <IconButton Icon={GrEdit} onClick={onOpen} />
                <IconButton
                  Icon={MdDeleteOutline}
                  onClick={() => deleteAsset(index, "ytLinks", ytLinkIndex)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default YTModal;
