import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Spin,
  Table,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  addBlogCategory,
  deleteBlogCategory,
  getBlogCategory,
  updateBlogCategory,
} from "./BlogApi";
import { ExtractDate, ExtractTime, messageConfiguration } from "../../../Utils";

function AddBlogCategory() {
  const [form] = Form.useForm();
  const [blogCategoryData, setBlogCategoryData] = useState([]);
  const [nextCursor, setNextCursor] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const [from, setFrom] = useState("add");
  const [filldata, setFilldata] = useState({});

  const createBlogCategory = (changedValues) => {
    if (from === "add") {
      const payload = {
        categorySlug: changedValues.categorySlug,
        categoryName: changedValues.categoryName,
      };
      addBlogCategory(payload)
        .then((response) => {
          if (response.success) {
            message.success({ content: response.message });
            form.resetFields();
            FetchBlogCategoryData();
          }
        })
        .catch((error) => console.error(error));
    } else {
      const payload = {
        id: filldata._id,
        categorySlug: changedValues.categorySlug,
        categoryName: changedValues.categoryName,
      };
      updateBlogCategory(payload)
        .then((response) => {
          if (response.success) {
            message.success({ content: response.message });
            form.resetFields();
            setFrom("add");
            FetchBlogCategoryData();
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const FetchBlogCategoryData = () => {
    getBlogCategory()
      .then((response) => {
        if (response.status) {
          setBlogCategoryData(response.data);
        }
      })
      .catch((err) => console.error("Error fetching data", err));
  };

  useEffect(() => {
    FetchBlogCategoryData();
  }, []);

  useEffect(() => {
    if (from === "edit" && filldata) {
      form.setFieldsValue({
        categoryName: filldata?.categoryName,
        categorySlug: filldata?.categorySlug,
      });
    } else {
      form.resetFields();
    }
  }, [from, filldata, form]);

  const DeleteAction = (record) => {
    deleteBlogCategory(record._id)
      .then((response) => {
        if (response.status) {
          message.success(response.message);
          FetchBlogCategoryData();
        }
      })
      .catch((error) => console.error(error));
  };

  const columns = [
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (_, record) => <p>{record.categoryName}</p>,
    },
    {
      title: "Category Slug",
      dataIndex: "categorySlug",
      key: "categorySlug",
      render: (_, record) => <p>{record.categorySlug}</p>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <p>
          {ExtractDate(record.createdAt)} • {ExtractTime(record.createdAt)}
        </p>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <Button
          size="small"
          onClick={() => {
            setFrom("edit");
            setFilldata(record);
          }}
        >
          <EditOutlined />
        </Button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this Blog Category?"
          onConfirm={() => DeleteAction(record)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Divider orientation="left">Add Blog Category</Divider>
      <Form
        layout="vertical"
        form={form}
        onFinish={createBlogCategory}
        initialValues={{ categoryName: "", categorySlug: "" }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Category Name"
              name="categoryName"
              rules={[
                { required: true, message: "Please enter a Name" },
                { type: "string", message: "Please enter a valid Name" },
              ]}
            >
              <Input placeholder="Enter Category Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Category Slug"
              name="categorySlug"
              rules={[
                { required: true, message: "Please enter Category Slug" },
                {
                  min: 6,
                  message: "Slug must be at least 6 characters",
                },
                {
                  pattern: /^[a-zA-Z0-9-]+$/,
                  message:
                    "Slug can only contain letters, numbers, and hyphens",
                },
              ]}
            >
              <Input placeholder="Enter Category Slug" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button type="primary" htmlType="submit">
            {from === "add" ? "Create Blog Category" : "Update Blog Category"}
          </Button>
        </Row>
      </Form>
      <Divider orientation="left">Blog Category List</Divider>
      <Table
        columns={columns}
        dataSource={blogCategoryData}
        pagination={false}
      />
    </div>
  );
}

export default AddBlogCategory;
